<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import {
		SiteService,
		DownloadService,
		TransactionService as Service,
		BankListService,
	} from "@/services"
	import StatusChangeModal from "./StatusChange"
	import LogModal from "./Log"
	import TransactionService from "@/services/transaction.service"
	import DepositStatus from "@/components/DepositStatus"

	export default {
		name: "BankTransferDepositList",
		data() {
			return {
				table: {
					filter: [
						{
							type: "input",
							value: "DEPOSIT",
							hidden: true,
							field: "type",
						},
						{
							type: "input",
							value: null,
							field: "uuid",
							name: "İşlem ID",
						},
						{
							type: "input",
							value: null,
							field: "processId",
							name: "Process ID",
						},
						{
							type: "input",
							value: null,
							field: "iban",
							name: "IBAN",
						},
						{
							type: "input",
							value: null,
							field: "amount",
							name: "Tutar",
						},
						{
							type: "input",
							value: null,
							field: "name",
							name: "Kullanıcı",
						},
						{
							type: "input",
							value: null,
							field: "userId",
							name: "Kullanıcı ID",
						},
						{
							type: "enum",
							value: null,
							field: "status",
							name: "Durum",
							enumType: "transactionTypes",
						},
						{
							type: "datepicker",
							value: [new Date(), new Date()],
							field: "dates",
							name: "Talep T.",
						},
						{
							type: "datepicker",
							value: [null, null],
							field: "updatedDates",
							name: "Onay T.",
						},
					],
					page: {
						title: "Havale & Eft Yatırımları",
						icon: "cash-multiple",
						actions: [
							{
								title: "Filtrele",
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData()
								},
							},
							{
								title: "Export",
								type: "success",
								icon: "file-excel",
								perm: "Deposit.ExcelExport",
								action: () => {
									this.getExportExcel()
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "depositBankTransfer",
						columns: [
							{
								field: "status",
								label: "Durum",
								sortable: true,
								renderComponent: DepositStatus,
							},
							{
								field: "site.name",
								label: "Site",
							},
							{
								field: "uuid",
								label: "İşlem ID",
								sortable: true,
							},
							{
								field: "bankAccount.name",
								label: "Hesap",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									val.bankAccount.name.length > 30
										? val.bankAccount.name.slice(0, 30) +
										  ".."
										: val.bankAccount.name,
							},
							{
								field: "bank.type",
								label: "Banka",
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									const logo = val.bankAccount?.bankList?.logo
									return `<img width="70" style="max-width:70px" src="${logo}" />`
								},
							},
							{
								field: "bankAccount.iban",
								label: "IBAN",
							},
							{
								field: "username",
								label: "Kullanıcı",
								sortable: true,
								column: "row",
								renderHtml: true,
								viewMethod: (val) => `${val.name}`,
							},
							{
								field: "userId",
								label: "Kullanıcı ID",
							},
							{
								field: "total",
								label: "Ücret",
								sortable: true,
								viewMethod: (val) =>
									`₺${this.numberFormat(val)}`,
							},
							{
								field: "createdAt",
								name: this.$store.state.setting.createdAt
									? "Talep T."
									: "Onay T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTime
									),
							},
							{
								field: "actions",
								column: "id",
								label: "İşlemler",
								width: "100px",
								actions: [
									{
										title: "Güncelle",
										type: "info",
										column: "row",
										perm: "Deposit.StatusChange",
										action: (data) => {
											if (data.status === "SUCCESSFUL") {
												this.$buefy.toast.open({
													message:
														"Onaylanan işlem üzerinde değişiklik yapamazsınız.",
													type: "is-warning",
												})
												return false
											}
											this.openModal({
												component: StatusChangeModal,
												props: {
													withdraw: { ...data },
												},
												// close: this.$refs.table.loadAsyncData,
											})
										},
									},
									{
										title: "Loglar",
										type: "warning",
										perm: "Deposit.Log",
										column: "row",
										action: (data) => {
											this.openModal({
												component: LogModal,
												styles: {
													width: 1000,
													height: 800,
												},
												props: {
													withdraw: { ...data },
												},
												// close: this.$refs.table.loadAsyncData,
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
		computed: {
			insertListener() {
				return `transactionDepositInserted${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
			updateListener() {
				return `transactionDepositUpdated${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
		},
		async mounted() {
			if (!this.$store.state.user.siteId) await this.getSites()
			await this.getBankList()
			setTimeout(() => {
				this.sockets.subscribe(this.updateListener, (val) => {
					val = JSON.parse(val)
					if (!val.status || !val.uuid || this.$refs.table.loading)
						return
					console.log("geçti")
					let newDatas = {
						WAITING: [],
						PENDING: [],
						SUCCESSFUL: [],
						UNSUCCESSFUL: [],
					}
					this.$refs.table.data.map((item, key) => {
						if (item.uuid === val.uuid) {
							item.status = val.status
							item.updatedAt = new Date(val.updatedAt)
							item.logs = val.logs
						}
						newDatas[item.status].push(item)
					})
					console.log("aaa")
					newDatas["WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PENDING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["SUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["UNSUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["WAITING"])
							.concat(newDatas["PENDING"])
							.concat(newDatas["SUCCESSFUL"])
							.concat(newDatas["UNSUCCESSFUL"]),
					]
				})
				this.sockets.subscribe(this.insertListener, (val) => {
					val = JSON.parse(val)
					if (this.$refs.table.pagination.page > 1) return
					val = JSON.parse(val)

					const bankListId = this.$refs.table.filter[0].value || null
					const site =
						this.$store.state.user.siteId ||
						this.$refs.table.filter[1].value ||
						null
					const uuid = this.$refs.table.filter[3].value || null
					const processId = this.$refs.table.filter[4].value || null
					const name = this.$refs.table.filter[5].value || null
					const userId = this.$refs.table.filter[6].value || null
					const amount = this.$refs.table.filter[7].value || null
					const status = this.$refs.table.filter[8].value || null
					if (
						(!site || val.site.uuid === site) &&
						(!uuid || val.uuid === uuid) &&
						(!processId || val.processId === processId) &&
						(!amount ||
							parseFloat(val.total) === parseFloat(amount)) &&
						(!name || val.name === name) &&
						(!bankListId ||
							val.bankAccount.bankName === bankListId) &&
						(!userId || val.userId === userId) &&
						(!status || val.status === status)
					) {
						if (!this.$refs.table.loading && this.$refs.table.data)
							this.$refs.table.data.unshift(val)
						// this.$refs.table.data.pop()
					}
					let newDatas = {
						WAITING: [],
						PENDING: [],
						SUCCESSFUL: [],
						UNSUCCESSFUL: [],
					}
					this.$refs.table.data.map((item) =>
						newDatas[item.status].push(item)
					)
					newDatas["WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PENDING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["SUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["UNSUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["WAITING"])
							.concat(newDatas["PENDING"])
							.concat(newDatas["SUCCESSFUL"])
							.concat(newDatas["UNSUCCESSFUL"]),
					]
				})
			}, 500)
		},
		methods: {
			getSites() {
				SiteService.all({ status: 1 }).then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "siteId",
						name: "Site",
					})
				})
			},
			getBankList() {
				BankListService.all().then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "bankListId",
						name: "Banka",
					})
				})
			},
			getExportExcel() {
				let filterParams = {}
				if (
					this.$refs.table.filter &&
					this.$refs.table.filter.length > 0
				) {
					this.$refs.table.filter.map((item) => {
						if (
							(item.value !== "" && item.value !== null) ||
							item.hidden === true
						) {
							if (item.type === "datepicker") {
								if (
									typeof item.value === "object" &&
									item.value.length > 0
								) {
									if (item.value[0] && item.value[1]) {
										const startDate = this.getIstanbulDate(
											item.value[0]
										)
										const endDate = this.getIstanbulDate(
											item.value[1],
											false
										)
										filterParams[item.field] = [
											startDate,
											endDate,
										]
									}
								} else {
									filterParams[item.field] = item.value
								}
							} else {
								filterParams[item.field] = item.value
							}
						}
					})
				}

				TransactionService.exportExcel({
					...filterParams,
					offset: 180,
				}).then(({ data: { path, filename } }) => {
					DownloadService.download({ path }).then((res2) => {
						const url = URL.createObjectURL(
							new Blob([res2.data], {
								type: "application/vnd.ms-excel",
							})
						)
						const link = document.createElement("a")
						link.href = url
						link.setAttribute("download", filename)
						document.body.appendChild(link)
						link.click()
					})
				})
			},
		},
		beforeDestroy() {
			this.sockets.unsubscribe(this.updateListener)
			this.sockets.unsubscribe(this.insertListener)
		},
	}
</script>
